import { LazyExpand, LazyTotalScore, LazyScore, LazyChina } from "..\\..\\..\\..\\src\\components\\post\\lazy";
import Card from "..\\..\\..\\..\\src\\components\\card\\brief";
import { Update, News } from "..\\..\\..\\..\\src\\components\\card\\timeline";
import * as React from 'react';
export default {
  LazyExpand,
  LazyTotalScore,
  LazyScore,
  LazyChina,
  Card,
  Update,
  News,
  React
};