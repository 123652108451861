import React from "react"
import Image from "./image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Card = ({slug, logo, bg1, bg2, bg3,bg4,bg5, p1, p2, score, url, text}) => (
    <div className="vpn-card container full">
        <div className="row">
              <div className="col-12 col-md-3 vpn-card-logo">
                <Image src={logo} alt={slug+" logo"} className="vpn-card-logo-img" style={{ objectFit: "contain" }} />
              </div>
              <div className="col-12 col-md-6 vpn-card-content">
                <p className="vpn-card-badges">{
                  bg1 !== false && <span class="badge badge-primary"> 支付宝 </span>
                }{
                  bg2 !== false && <span>&nbsp;<span class="badge badge-warning"> 免翻墙 </span></span>
                }{
                  (bg3 !== undefined && bg3 !== false) && <span>&nbsp;<span class="badge rounded-pill badge-secondary"> SSR </span></span>
                }{
                  (bg4 !== undefined && bg4 !== false) && <span>&nbsp;<span class="badge rounded-pill badge-secondary"> VPN </span></span>
                }{
                  (bg5 !== undefined && bg5 !== false) && <span>&nbsp;<span class="badge badge-success"> 路由器 </span></span>
                 }</p>
                <hr className="vpn-card-hr"/>
                {p1 && <p className="vpn-card-text" ><span role="img" aria-label="check" >✔️ </span>{p1}</p>}
                {p2 && <p className="vpn-card-text" ><span role="img" aria-label="check" >✔️ </span>{p2}</p>}
              </div>
              <div className="col-12 col-md-3">
                <div className="vpn-card-score">
                  <p className="vpn-card-score-p">{score.toFixed(1)}</p>
                </div>
                <div className="vpn-card-link">
                    <OutboundLink class="btn vpn-link" role="button" target="_blank" rel="noreferrer nofollow"
                        href={url}
                        >{text}
                    </OutboundLink>
                </div>
              </div>
        </div>
    </div>
)

export default Card