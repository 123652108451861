import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Card from "../card"

const Full = ({slogan}) => {
    const data = useStaticQuery(graphql`
    query {
      refer: allReferJson {
        edges {
          node {
            vpn
            url
            logo{
              childImageSharp{
                fluid(maxHeight: 140) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            tech
            zhifubao
            mianfanqiang
            platform
            moneyback
          }
        }
      }
      score: allDataJson{
        edges {
          node {
            foreignRiview {
              vpns {
                slug
                average {
                  score
                }
              }
            }
          }
        }
      }
    }
  `)

    const content = data.refer.edges
    const score = data.score.edges.filter((edge)=> {return edge.node.foreignRiview !== null})[0].node.foreignRiview
    let vpns = []
    score.vpns.forEach((vpn)=>{
        const slug = vpn.slug
        const vpnRefer = content.filter((refer)=> {return refer.node.vpn === slug;})[0].node
        // console.log(vpnRefer.platform.includes("Router"))
        if (vpnRefer.platform !== null && vpn.average.score){
            vpns.push({
            "slug": slug,
            "url": vpnRefer.url,
            "logo": vpnRefer.logo,
            "tech": vpnRefer.tech,
            "zhifubao": vpnRefer.zhifubao,
            "mianfanqiang": vpnRefer.mianfanqiang,
            "score": vpn.average.score,
            "platform": vpnRefer.platform,
            "money": vpnRefer.moneyback
            })
        }
    })


    return(
        <>
        {vpns.map((vpn)=><Card 
            slug={vpn.slug}
            logo={vpn.logo.childImageSharp}
            bg1={vpn.zhifubao}
            bg2={vpn.mianfanqiang}
            bg3={vpn.tech.includes("SSR")}
            bg4={vpn.tech.includes("VPN")}
            bg5={vpn.platform.includes("Router")}
            p1={slogan}
            p2={vpn.money+"天内退款"}
            score={vpn.score}
            url={vpn.url}
            text="前往官网"
        />)}
    </>)
}

export default Full