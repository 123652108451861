import Card from "..\\..\\..\\..\\src\\components\\card\\brief";
import { LazyExpand, LazyOSGuides, LazyReview, LazyTotalScore, LazyScore, LazyCompareRadar, LazyChina } from "..\\..\\..\\..\\src\\components\\post\\lazy";
import * as React from 'react';
export default {
  Card,
  LazyExpand,
  LazyOSGuides,
  LazyReview,
  LazyTotalScore,
  LazyScore,
  LazyCompareRadar,
  LazyChina,
  React
};