import React from "react"
import {useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Refer = ({vpn, p, link,rel, keyword}) =>{
  const data = useStaticQuery(graphql`
    query {
      allReferJson {
        edges {
          node {
            vpn
            url
          }
        }
      }
    }
  `)

  const content = data.allReferJson.edges
  const item = content.filter((refer)=> {return refer.node.vpn === vpn;})[0]
  const url = link === undefined ? item.node.url: link
  let referURL;
  if (keyword !== undefined){
    const newLink = url.replace("/vpn/","/key/")
    referURL = newLink +"/?keyword="+keyword
  }else{
    referURL = url
  }
  // console.log(referURL)

  return (
    <OutboundLink class="btn vpn-link" 
      href={referURL} 
      role="button"
      target="_blank"
      rel={rel === undefined ? "noreferrer nofollow" : rel}
    >{p}
    </OutboundLink>
  )
}

export default Refer