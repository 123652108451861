import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../image"
import Refer from "../card/refer"
import "../../styles/parts/vpn-card.sass"

const Card = ({vpn, p1, p2, link, keyword}) => {
    const data = useStaticQuery(graphql`
    query {
      refer: allReferJson {
        edges {
          node {
            vpn
            logo{
              childImageSharp{
                fluid(maxHeight: 140) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            zhifubao
            mianfanqiang
          }
        }
      }
      score: allDataJson{
        edges {
          node {
            foreignRiview {
              vpns {
                slug
                average {
                  score
                }
              }
            }
          }
        }
      }
    }
  `)

  const content = data.refer.edges
  const item = content.filter((refer)=> {return refer.node.vpn === vpn;})[0]
  const vpnData = item.node

  const score = data.score.edges.filter((edge)=> {return edge.node.foreignRiview !== null})[0].node.foreignRiview
  const vpnScore = score.vpns.filter((item)=>{return item.slug === vpn})[0].average.score.toFixed(1)
  return(
      <div className="vpn-card container">
          <div className="row">
              <div className="col-12 col-md-3 vpn-card-logo">
                <Image src={vpnData.logo.childImageSharp} alt={vpn+" logo"} className="vpn-card-logo-img" style={{ objectFit: "contain" }} />
              </div>
              <div className="col-12 col-md-6 vpn-card-content">
                <p className="vpn-card-badges">{
                  vpnData.zhifubao !== false && <span class="badge badge-primary"> 支付宝 </span>
                }&nbsp;{
                  vpnData.mianfanqiang !== false && <span class="badge badge-warning"> 免翻墙访问 </span>
                }</p>
                <hr className="vpn-card-hr"/>
                {p1 && <p className="vpn-card-text" ><span role="img" aria-label="check" >✔️ </span>{p1}</p>}
                {p2 && <p className="vpn-card-text" ><span role="img" aria-label="check" >✔️ </span>{p2}</p>}
              </div>
              <div className="col-12 col-md-3">
                <div className="vpn-card-score">
                  <p className="vpn-card-score-p">{vpnScore}</p>
                </div>
                <div className="vpn-card-link">
                  <Refer vpn={vpn} p={link} keyword={keyword} />
                </div>
              </div>
          </div>
      </div>
  )
}

export default Card;