import React from "react"
import Img from "gatsby-image"

const Image =({src, url, alt, className, style, load})=>{
    return(
        <>
            { (src !== null  && src.fixed !== null) && <Img fixed={src.fixed} alt={alt} className={className} imgStyle={style} />}
            { (src !== null  && src.fluid !== null ) && <Img fluid={src.fluid} alt={alt} className={className} imgStyle={style} />}
            { (src === null && url) && <img loading={load === null ? "eager" : load } src={url} alt={alt} className={className} />}
        </>
    )
}
export default Image