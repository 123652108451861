// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-review-js": () => import("./../../../src/pages/about/review.js" /* webpackChunkName: "component---src-pages-about-review-js" */),
  "component---src-pages-about-speedtest-js": () => import("./../../../src/pages/about/speedtest.js" /* webpackChunkName: "component---src-pages-about-speedtest-js" */),
  "component---src-pages-about-statement-js": () => import("./../../../src/pages/about/statement.js" /* webpackChunkName: "component---src-pages-about-statement-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about/us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-guides-index-js": () => import("./../../../src/pages/guides/index.js" /* webpackChunkName: "component---src-pages-guides-index-js" */),
  "component---src-pages-guides-report-js": () => import("./../../../src/pages/guides/report.js" /* webpackChunkName: "component---src-pages-guides-report-js" */),
  "component---src-pages-guides-tutorial-js": () => import("./../../../src/pages/guides/tutorial.js" /* webpackChunkName: "component---src-pages-guides-tutorial-js" */),
  "component---src-pages-guides-yuanli-js": () => import("./../../../src/pages/guides/yuanli.js" /* webpackChunkName: "component---src-pages-guides-yuanli-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vpn-ranks-js": () => import("./../../../src/pages/vpn-ranks.js" /* webpackChunkName: "component---src-pages-vpn-ranks-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

