import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "../image"
import ReactStoreIndicator from 'react-score-indicator'
import Refer from "../card/refer"
import "../../styles/parts/vpn-card.sass"
import "../../styles/parts/review.sass"

const TrialList = () => {
    const data = useStaticQuery(graphql`
    query {
      refer: allReferJson {
        edges {
          node {
            vpn
            path
            logo{
              childImageSharp{
                fluid(maxHeight: 140) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            tech
            zhifubao
            mianfanqiang
            trial
            trial_plan
          }
        }
      }
      score: allDataJson{
        edges {
          node {
            foreignRiview {
              vpns {
                slug
                average {
                  score
                }
              }
            }
          }
        }
      }
    }
  `)

  const content = data.refer.edges
  const score = data.score.edges.filter((edge)=> {return edge.node.foreignRiview !== null})[0].node.foreignRiview
  let vpns = []
  score.vpns.forEach((vpn)=>{
    const slug = vpn.slug
    const vpnRefer = content.filter((refer)=> {return refer.node.vpn === slug;})[0].node
    if (vpnRefer.trial){
      vpns.push({
        "slug": slug,
        "path": vpnRefer.path,
        "logo": vpnRefer.logo,
        "tech": vpnRefer.tech,
        "zhifubao": vpnRefer.zhifubao,
        "mianfanqiang": vpnRefer.mianfanqiang,
        "score": vpn.average.score,
        "plan": vpnRefer.trial_plan
      })
    }
  })
  return(
    <div>
      {vpns.map((vpn) =>
        <div className="container vpn-card-large">
            <div className="row">
                <div className="col-12 col-md-3 vpn-card-logo">
                  <Image src={vpn.logo.childImageSharp} alt={vpn.slug+" logo"} className="vpn-card-logo-img" style={{ objectFit: "contain" }} />
                </div>
                <div className="col-12 col-md-6 vpn-card-content">
                  
                  <p className="vpn-card-badges">{
                    vpn.zhifubao !== false && <span class="badge badge-primary"> 支付宝 </span>
                  }&nbsp;{
                    vpn.mianfanqiang !== false && <span class="badge badge-warning"> 免翻墙访问 </span>
                  }&nbsp;{
                    vpn.tech.includes("SSR") && <span class="badge rounded-pill badge-success"> SSR </span>
                  }&nbsp;{
                    vpn.tech.includes("VPN") && <span class="badge rounded-pill badge-secondary"> VPN </span>
                  }</p>
                  <p className="vpn-card-text" ><span role="img" aria-label="check" >✔️ </span>{vpn.plan}</p>
                </div>
                <div className="col-12 col-md-3">
                  <div className="vpn-score" >
                    <ReactStoreIndicator
                      value={vpn.score*10}
                      maxValue={100}
                      width={100}
                    />
                  </div>
                </div>
            </div>
            <div className="box-links">
            <div className="review-links">
              <Refer vpn={vpn.slug} p="前往试用" />
              <Link 
                  class="btn vpn-page" 
                  role="button" 
                  to={vpn.path}
              >{"查看我们的评测"}</Link>
              </div>
            </div>
        </div>)
      }
    </div>
  )
}

export default TrialList;