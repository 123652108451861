import React from "react"
import loadableVisibility from "react-loadable-visibility/loadable-components";
const Placeholder = () => null

// const LazyThing = props => {
//   // While the component is loading, we'll render a fallback placeholder.
//   // (The Placeholder is a component that renders nothing).
//   const [Component, setComponent] = React.useState(() => Placeholder)
//   // After the initial render, kick off a dynamic import to fetch
//   // the real component, and set it into our state.
//   React.useEffect(() => {
//     import("./Thing.js").then(Thing => setComponent(() => Thing.default))
//   }, [])
//   return <Component {...props} />
// }

const LazyExpand = props => {
    const [Component, setComponent] = React.useState(() => Placeholder)
    React.useEffect(() => {
      import("../card/accordion.js").then(Expand => setComponent(() => Expand.default))
    }, [])
    return <Component {...props} />
}

const LazyWorld = props => {
    const [Component, setComponent] = React.useState(() => Placeholder)
    React.useEffect(() => {
      import("../dataVisual/world.js").then(World => setComponent(() => World.default))
    }, [])
    return <Component {...props} />
}
const LazyReviewCard = props => {
    const [Component, setComponent] = React.useState(() => Placeholder)
    React.useEffect(() => {
      import("../card/review.js").then(ReviewCard => setComponent(() => ReviewCard.default))
    }, [])
    return <Component {...props} />
}

const LazyOSGuides = props => {
    const [Component, setComponent] = React.useState(() => Placeholder)
    React.useEffect(() => {
      import("../card/os_guides.js").then(OSGuides => setComponent(() => OSGuides.default))
    }, [])
    return <Component {...props} />
}

const LazyReview = props => {
    const [Component, setComponent] = React.useState(() => Placeholder)
    React.useEffect(() => {
      import("../dataVisual/reviewFull.js").then(Review => setComponent(() => Review.default))
    }, [])
    return <Component {...props} />
}
const TotalScore = React.lazy(() =>
import("../dataVisual/totalScore.js")
)
const LazyTotalScore = props => {
  const isSSR = typeof window === "undefined"
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <TotalScore {...props}  />
        </React.Suspense>
      )}
    </>
  )
}
const Score = React.lazy(() =>
import("../dataVisual/score.js")
)
const LazyScore = props => {
  const isSSR = typeof window === "undefined"
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Score {...props}  />
        </React.Suspense>
      )}
    </>
  )
}
const LazyCompareRadar = props => {
    const [Component, setComponent] = React.useState(() => Placeholder)
    React.useEffect(() => {
      import("../dataVisual/compare.js").then(CompareRadar => setComponent(() => CompareRadar.default))
    }, [])
    return <Component {...props} />
}

const China = loadableVisibility(/* #__LOADABLE__ */ () => import("../dataVisual/china.js"), {
  fallback: <div />
});
const LazyChina = props => {
  return <China {...props} />
}
const LazySpeedOut = props => {
  const [Component, setComponent] = React.useState(() => Placeholder)
  React.useEffect(() => {
    import("../dataVisual/speedOut.js").then(Speed => setComponent(() => Speed.default))
  }, [])
  return <Component {...props} />
}

export {
  LazyExpand,
  LazyWorld,
  LazyReviewCard,
  LazyOSGuides,
  LazyReview,
  LazyTotalScore,
  LazyScore,
  LazyCompareRadar,
  LazyChina,
  LazySpeedOut
}
