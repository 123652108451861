import React from "react"
import {Link} from "gatsby"
import "../../styles/parts/os-guides.sass"
import mac_icon from "../../icons/card/OSX_yellow.svg"
import win_icon from "../../icons/card/Windows_yellow.svg"
import android_icon from "../../icons/card/Android_yellow.svg"
import ios_icon from "../../icons/card/IOS_yellow.svg"

const OSGuides = ({win_p,win_url,mac_p,mac_url,ios_p,ios_url,and_p,and_url}) =>(
    <div className="os-guides">
        <div className="row" >
            <div className="col-12 col-lg-6 item">
                <div className="icon"><img src={win_icon} alt="windows 系统" /></div>
                <div className="intro">
                    <h4><Link to={win_url}>Windows 电脑 ➜</Link></h4>
                    <p>{win_p}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6 item">
                <div className="icon"><img src={mac_icon} alt="mac 系统" /></div>
                <div className="intro">
                    <h4><Link to={mac_url} >Mac 电脑 ➜</Link></h4>
                    <p>{mac_p}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6 item">
                <div className="icon"><img src={ios_icon} alt="IOS 系统, iPhone 手机" /></div>
                <div className="intro">
                    <h4><Link to={ios_url} >iPhone（IOS系统）➜</Link></h4>
                    <p>{ios_p}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6 item">
                <div className="icon"><img src={android_icon} alt="Android 系统" /></div>
                <div className="intro">
                    <h4><Link to={and_url} >安卓系统 ➜</Link></h4>
                    <p>{and_p}</p>
                </div>
            </div>
        </div>
    </div>
)

export default OSGuides

