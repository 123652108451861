import React from "react"
import {Timeline} from "antd"
import 'antd/dist/antd.css'

const Update = ({children}) => (
    <div className="timeline">
        <Timeline mode="left" >
        {children}
        </Timeline>
    </div>
    
)

const News = ({children}) => (
    <Timeline.Item>
        {children}
    </Timeline.Item>
)

export {Update, News}